import { Home } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { Drawer } from "../../Components/Drawer";
import useSWR from "swr";
import { SHOW_ERROR } from "../../Context/globalActions.types";
import globalContext from "../../Context/globalContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

export default function CropDetails() {
  const [rows, setRows] = useState([]);

  const [dtl, setDtl] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (dtl) => {
    setOpen(true);
    setDtl(dtl);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { dispatch } = useContext(globalContext);

  const { data, error } = useSWR("/crops");

  useEffect(() => {
    if (data) setRows(data.data.data);
  }, [data]);

  useEffect(() => {
    if (error)
      dispatch({
        type: SHOW_ERROR,
        payload: "Something went wrong",
      });
  }, [error]);

  return (
    <Drawer>
      <Details open={open} handleClose={handleClose} dtl={dtl} />
      <div className="mt-10 mx-5">
        <nav
          className="flex w-full items-start rounded-md bg-gray-100 p-2"
          aria-label="Breadcrumb"
        >
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <a
                href="#"
                className="ml-1 inline-flex text-sm font-medium text-gray-800 hover:underline md:ml-2"
              >
                <Home className="mr-4 h-4 w-4" />
                Admin
              </a>
            </li>
            <li>
              <div className="flex items-center">
                <span className="mx-2.5 text-gray-800 ">/</span>
                <a
                  href="#"
                  className="ml-1 text-sm font-medium text-gray-800 hover:underline md:ml-2"
                >
                  Crop Data
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <div>
          <h2 className="text-2xl font-bold leading-tight text-black my-4">
            Crop Details
          </h2>
          <div className="grid grid-cols-3 gap-7">
            {rows.map((e) => (
              <div
                className="h-full pb-3 w-full rounded-lg shadow cursor-pointer"
                onClick={() => {
                  handleClickOpen(e);
                }}
              >
                <div>
                  <div className="font-semibold ml-2 mt-2">
                    Farmer Farm Survey No:
                  </div>
                  <div className="ml-2 text-sm">
                    {e.farmerfarms.farmSurveyNo}
                  </div>
                </div>
                <div>
                  <div className="font-semibold ml-2 mt-2">
                    Farmer Farm Name:
                  </div>
                  <div className="ml-2 text-sm">{e.farmerfarms.farmName}</div>
                </div>
                <div>
                  <div className="font-semibold ml-2 mt-2">Address:</div>
                  <div className="ml-2 text-sm">{e.addr}</div>
                </div>
                <div>
                  <div className="font-semibold ml-2 mt-2">Crop Desc:</div>
                  <div className="ml-2 text-sm">
                    {e.farmerfarms.cropdtl ? e.farmerfarms.cropdtl.desc : ""}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

function Details({ open, handleClose, dtl }) {
  console.log(dtl);

  if (!dtl) return <div></div>;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Crop Details</DialogTitle>
      <DialogContent>
        {dtl.farmerfarms.map((e, index) => (
          <div key={index}>
            <div className="grid grid-cols-4 my-3">
              <div>
                <div className="font-bold">Farm Name</div>
                <div>{e.farmName ? e.farmName : ""}</div>
              </div>
              <div>
                <div className="font-bold">Farm Survey No</div>
                <div>{e.farmSurveyNo ? e.farmSurveyNo : ""}</div>
              </div>
            </div>
            <div className="mt-2">Crop Details: </div>
            {e.cropdtls.length !== 0 ? (
              <TableContainer component={Paper} className="mt-2">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {e.cropdtls.map((row) => (
                      <TableRow key={row.desc}>
                        <TableCell component="th" scope="row">
                          {row.desc}
                        </TableCell>
                        <TableCell>
                          {new Date(row.createdAt).toLocaleString("en-Us", {
                            dateStyle: "medium",
                          })}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              "No Crop Details Found"
            )}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
