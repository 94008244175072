import React, { useEffect, useState, useContext } from "react";

import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import GlobalContext from "../Context/globalContext";
import { HIDE_ERROR } from "../Context/globalActions.types";

const ErrorSnakBar = ({ message }) => {
  const [showSnakBar, setShowSnakBar] = useState(null);
  const { dispatch } = useContext(GlobalContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSnakBar(null);
      dispatch({
        type: HIDE_ERROR,
      });
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [showSnakBar]);

  useEffect(() => {
    setShowSnakBar(message);
  }, []);

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={showSnakBar ? true : false}
        key={"bottom" + "right"}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          {showSnakBar}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ErrorSnakBar;
