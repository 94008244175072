import { Home } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { Drawer } from "../../Components/Drawer";
import useSWR from "swr";
import { SHOW_ERROR } from "../../Context/globalActions.types";
import globalContext from "../../Context/globalContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Instance, URL } from "../../Config";

export default function BidData() {
  const [dtl, setDtl] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (dtl) => {
    setOpen(true);
    setDtl(dtl);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [rows, setRows] = useState([]);

  const { dispatch } = useContext(globalContext);

  const { data, error } = useSWR("/bid");

  useEffect(() => {
    if (data) setRows(data.data.data);
  }, [data]);

  useEffect(() => {
    if (error)
      dispatch({
        type: SHOW_ERROR,
        payload: "Something went wrong",
      });
  }, [error]);

  return (
    <Drawer>
      <Details dtl={dtl} handleClose={handleClose} open={open} />
      <div className="mt-10 mx-5">
        <nav
          className="flex w-full items-start rounded-md bg-gray-100 p-2"
          aria-label="Breadcrumb"
        >
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <a
                href="#"
                className="ml-1 inline-flex text-sm font-medium text-gray-800 hover:underline md:ml-2"
              >
                <Home className="mr-4 h-4 w-4" />
                Admin
              </a>
            </li>
            <li>
              <div className="flex items-center">
                <span className="mx-2.5 text-gray-800 ">/</span>
                <a
                  href="#"
                  className="ml-1 text-sm font-medium text-gray-800 hover:underline md:ml-2"
                >
                  Bid Data
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <div>
          <h2 className="text-2xl font-bold leading-tight text-black my-4">
            Biding Data
          </h2>
          <div className="grid grid-cols-4 gap-7">
            {rows.map((e) => (
              <div
                className="h-48 w-full rounded-lg shadow cursor-pointer"
                onClick={() => {
                  handleClickOpen(e);
                }}
              >
                <div className="flex">
                  <div>
                    <div className="font-semibold ml-2 mt-2">Farmer Name:</div>
                    <div className="ml-2 text-sm">
                      {e.farmerFarms.farmerdtl.usersf.fName}
                    </div>
                  </div>
                  <div>
                    <div className="font-semibold ml-2 mt-2">
                      Investor Name:
                    </div>
                    <div className="ml-2 text-sm">
                      {e.investorDtl.users.fName}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="font-semibold ml-2 mt-2">
                    Farmer Farm Name:
                  </div>
                  <div className="ml-2 text-sm">{e.farmerFarms.farmName}</div>
                </div>
                <div>
                  <div className="font-semibold ml-2 mt-2">Rate:</div>
                  <div className="ml-2 text-sm">₹{e.rate}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

function Details({ open, handleClose, dtl }) {
  console.log(dtl);

  if (!dtl) return <div></div>;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Bid Details</DialogTitle>
      <DialogContent>
        <div className="grid grid-cols-4 my-3">
          <div>
            <div className="font-bold">Description:</div>
            <div>{dtl.desc ? dtl.desc : ""}</div>
          </div>
          <div>
            <div className="font-bold">Rate</div>
            <div>{dtl.rate ? dtl.rate : ""}</div>
          </div>
          <div>
            <div className="font-bold">Bid Status: </div>
            <span
              className={
                dtl.isAccepted != null
                  ? dtl.isAccepted
                    ? `bg-green-500 text-white py-0.5 px-2 rounded-md `
                    : `bg-red-500 text-white py-0.5 px-2 rounded-md `
                  : `bg-yellow-300 text-white py-0.5 px-2 rounded-md `
              }
            >
              {dtl.isAccepted != null
                ? dtl.isAccepted
                  ? "Accepted"
                  : "Deny"
                : "Pending"}
            </span>
          </div>
          <div>
            <div className="font-bold">Farmer Farm Name</div>
            <div>
              {dtl.farmerFarms.farmName ? dtl.farmerFarms.farmName : ""}
            </div>
          </div>
          <div>
            <div className="font-bold">Farmer Survey No</div>
            <div>
              {dtl.farmerFarms.farmSurveyNo ? dtl.farmerFarms.farmSurveyNo : ""}
            </div>
          </div>
        </div>
        {dtl.investorDoc ? (
          <div className="flex">
            <div className="font-bold mr-2">Investor Doc: </div>
            <Button
              onClick={() => {
                window.open(
                  URL + "/uploads/" + dtl.investorDoc,
                  "_blank",
                  "noreferrer"
                );
              }}
              color="primary"
              variant="outlined"
            >
              Open
            </Button>
          </div>
        ) : null}
        {dtl.farmerDoc ? (
          <div className="flex">
            <div className="font-bold mr-2">Farmer Doc: </div>
            <Button
              onClick={() => {
                window.open(
                  URL + "/uploads/" + dtl.farmerDoc,
                  "_blank",
                  "noreferrer"
                );
              }}
              color="primary"
              variant="outlined"
            >
              Open
            </Button>
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
