import { Home } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { Drawer } from "../../Components/Drawer";
import useSWR, { useSWRConfig } from "swr";
import { SHOW_ERROR } from "../../Context/globalActions.types";
import globalContext from "../../Context/globalContext";
import { Instance } from "../../Config";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

export default function InvestorFarmListing() {
  const [rows, setRows] = useState([]);

  const [formData, setFormData] = useState({
    rate: "",
    desc: "",
    ffId: "",
    fId: "",
    file: "",
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setFormData({
      rate: "",
      desc: "",
      ffId: "",
      fId: "",
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { dispatch } = useContext(globalContext);

  const { mutate } = useSWRConfig();

  const { data, error } = useSWR("/listingFarms");

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddBid = () => {
    const formDataa = new FormData();

    formDataa.append("rate", formData.rate);
    formDataa.append("desc", formData.desc);
    formDataa.append("ffId", formData.ffId);
    formDataa.append("fId", formData.fId);
    formDataa.append("doc", formData.file);

    Instance.post("/bid", formData)
      .then(() => {
        handleClose();
        mutate("/listingFarms");
        setFormData({
          rate: "",
          desc: "",
          ffId: "",
          fId: "",
        });
      })
      .catch((err) => {
        dispatch({
          type: SHOW_ERROR,
          payload: err.response.data.message || "Something Went Wrong",
        });
      });
  };

  useEffect(() => {
    if (data) setRows(data.data.data);
  }, [data]);

  useEffect(() => {
    if (error)
      dispatch({
        type: SHOW_ERROR,
        payload: "Something went wrong",
      });
  }, [error]);

  return (
    <Drawer>
      <AddDialog
        open={open}
        formData={formData}
        handleClose={handleClose}
        handleInputChange={handleInputChange}
        handleSubmit={handleAddBid}
        setFormData={setFormData}
      />
      <div className="mt-10 mx-5">
        <nav
          className="flex w-full items-start rounded-md bg-gray-100 p-2"
          aria-label="Breadcrumb"
        >
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <a
                href="#"
                className="ml-1 inline-flex text-sm font-medium text-gray-800 hover:underline md:ml-2"
              >
                <Home className="mr-4 h-4 w-4" />
                Admin
              </a>
            </li>
            <li>
              <div className="flex items-center">
                <span className="mx-2.5 text-gray-800 ">/</span>
                <a
                  href="#"
                  className="ml-1 text-sm font-medium text-gray-800 hover:underline md:ml-2"
                >
                  Farm Listing
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <div>
          <h2 className="text-2xl font-bold leading-tight text-black my-4">
            Farm Listing Data
          </h2>
          <div className="grid grid-cols-4 gap-7">
            {rows.map((e) => (
              <div className="h-full w-full rounded-lg shadow cursor-pointer mb-3">
                <div className="flex">
                  <div>
                    <div className="font-semibold ml-2 mt-2">Farmer Name:</div>
                    <div className="ml-2 text-sm">
                      {e.farmerdtl.usersf.fName}
                    </div>
                  </div>
                  <div>
                    <div className="font-semibold ml-2 mt-2">City:</div>
                    <div className="ml-2 text-sm">{e.farmerdtl.city}</div>
                  </div>
                </div>
                <div>
                  <div className="font-semibold ml-2 mt-2">
                    Farmer Farm Name:
                  </div>
                  <div className="ml-2 text-sm">{e.farmName}</div>
                </div>
                <div className="text-center">
                  <button
                    onClick={() => {
                      handleClickOpen();
                      setFormData({
                        ...formData,
                        ffId: e.ffId,
                        fId: e.fId,
                      });
                    }}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md bg-black px-3.5 py-1 font-semibold leading-7 text-white hover:bg-black/80"
                  >
                    Bid
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

function AddDialog({
  open,
  handleClose,
  handleInputChange,
  formData,
  handleSubmit,
  setFormData,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Place Bid</DialogTitle>
      <DialogContent>
        <form>
          <div>
            <label htmlFor="" className="text-base font-medium text-gray-900">
              Rate
            </label>
            <div className="mt-2">
              <input
                className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type="text"
                placeholder="Rate"
                name="rate"
                value={formData.rate}
                onChange={handleInputChange}
              ></input>
            </div>
          </div>
          <div className="mt-2">
            <label htmlFor="" className="text-base font-medium text-gray-900">
              Description
            </label>
            <div className="mt-2">
              <input
                className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type="text"
                placeholder="Description"
                name="desc"
                value={formData.desc}
                onChange={handleInputChange}
              ></input>
            </div>
          </div>
          <div className="mt-2">
            <div>Doc Upload:</div>
            <input
              type="file"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  file: e.target.files[0],
                });
              }}
            />
          </div>

          <div className="mt-3">
            <div className="font-semibold text-xl mb-2">Payment Details:</div>
            <div>
              <div>
                <label
                  htmlFor=""
                  className="text-base font-medium text-gray-900"
                >
                  Card Number
                </label>
                <div className="mt-2">
                  <input
                    className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                    type="text"
                    placeholder="4444 4444 4444 4444"
                    name="Card Number"
                  ></input>
                </div>
              </div>
              <div className="flex">
                <div className="mt-2">
                  <label
                    htmlFor=""
                    className="text-base font-medium text-gray-900"
                  >
                    Expiry Date
                  </label>
                  <div className="mt-2">
                    <input
                      className="flex h-10 w-1/2 rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                      type="text"
                      placeholder="MM/YY"
                      name="Expiry Date"
                    ></input>
                  </div>
                </div>
                <div className="mt-2 ml-3">
                  <label
                    htmlFor=""
                    className="text-base font-medium text-gray-900"
                  >
                    CVV
                  </label>
                  <div className="mt-2">
                    <input
                      className="flex h-10 w-1/2 rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                      type="text"
                      placeholder="123"
                      name="cvv"
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
