import { useReducer } from "react";
import "./App.css";
import { Instance } from "./Config";
import GlobalReducer from "./Context/globalreducers";
import { SWRConfig } from "swr";
import GlobalContext from "./Context/globalContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ErrorSnakBar from "./Components/ErrorSnakBar";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import Onboarding from "./Pages/Onboarding";
import Dashboard from "./Pages/admin/dashboard";
import Farmer from "./Pages/admin/farmer";
import Investor from "./Pages/admin/Investor";
import CropRegistration from "./Pages/farmer/CropRegistration";
import MarketPlace from "./Pages/admin/marketplace";
import BidData from "./Pages/admin/biddata";
import AllUsers from "./Pages/admin/user";
import HomePage from "./Pages/HomePage";
import CropDetails from "./Pages/farmer/CropDetails";
import FarmerProfile from "./Pages/farmer/Profile";
import FarmerBidData from "./Pages/farmer/biddata";
import InvestorProfile from "./Pages/investor/Profile";
import InvestorBidData from "./Pages/investor/biddata";
import InvestorFarmListing from "./Pages/investor/farmData";

export const initalValue = {
  user: null,
  error: null,
};

function App() {
  const [globalContext, dispatch] = useReducer(GlobalReducer, initalValue);
  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        fetcher: (resource, init) =>
          Instance.get(resource, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }).then((res) => res),
      }}
    >
      <GlobalContext.Provider value={{ globalContext, dispatch }}>
        {globalContext.error ? (
          <ErrorSnakBar message={globalContext.error} />
        ) : null}
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/farmer" element={<Farmer />} />
            <Route path="/admin/investor" element={<Investor />} />
            <Route path="/admin/marketplace" element={<MarketPlace />} />
            <Route path="/admin/biddata" element={<BidData />} />
            <Route path="/admin/users" element={<AllUsers />} />
            <Route path="/farmer/cropreg" element={<CropRegistration />} />
            <Route path="/farmer/cropdtl" element={<CropDetails />} />
            <Route path="/farmer/profile" element={<FarmerProfile />} />
            <Route path="/farmer/biddata" element={<FarmerBidData />} />
            <Route path="/investor/profile" element={<InvestorProfile />} />
            <Route path="/investor/biddata" element={<InvestorBidData />} />
            <Route
              path="/investor/farmlisting"
              element={<InvestorFarmListing />}
            />
            <Route path="/" element={<HomePage />} />
          </Routes>
        </Router>
      </GlobalContext.Provider>
    </SWRConfig>
  );
}

export default App;
