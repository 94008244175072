import axios from "axios";

export const URL =
  process.env.NODE_ENV === "production"
    ? "https://farmer.skyhubs.in"
    : "http://localhost:4000";

export const Instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://farmer.skyhubs.in/api" //TODO: Add the production url
      : "http://localhost:4000/api",
});

Instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token;
  return config;
});

Instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);
