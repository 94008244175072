import { Home } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { Drawer } from "../../Components/Drawer";
import useSWR from "swr";
import { SHOW_ERROR } from "../../Context/globalActions.types";
import globalContext from "../../Context/globalContext";

export default function InvestorProfile() {
  const { dispatch } = useContext(globalContext);

  const [rows, setRows] = useState([]);

  const { data, error } = useSWR("/profile");

  useEffect(() => {
    if (data) setRows(data.data.data);
  }, [data]);

  useEffect(() => {
    if (error)
      dispatch({
        type: SHOW_ERROR,
        payload: "Something went wrong",
      });
  }, [error]);

  return (
    <Drawer>
      <div className="mt-10 mx-5">
        <nav
          className="flex w-full items-start rounded-md bg-gray-100 p-2"
          aria-label="Breadcrumb"
        >
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <a
                href="#"
                className="ml-1 inline-flex text-sm font-medium text-gray-800 hover:underline md:ml-2"
              >
                <Home className="mr-4 h-4 w-4" />
                Admin
              </a>
            </li>
            <li>
              <div className="flex items-center">
                <span className="mx-2.5 text-gray-800 ">/</span>
                <a
                  href="#"
                  className="ml-1 text-sm font-medium text-gray-800 hover:underline md:ml-2"
                >
                  Profile
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <div>
          <h2 className="text-2xl font-bold leading-tight text-black my-4">
            Profile
          </h2>
          <div className="grid grid-cols-4 gap-x-3">
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                First Name:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].fName : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                Last Name:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].lName : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                User Name:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].username : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                Address:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].users.addr : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                City:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].users.city : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                State:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].users.state : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                Pincode:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].users.pincode : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                Mobile No:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].users.mobile : ""}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
