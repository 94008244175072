import { initalValue } from "../App";
import {
  SIGN_IN,
  SIGN_OUT,
  SHOW_ERROR,
  HIDE_ERROR,
} from "./globalActions.types";

const GlobalReducer = (state, action) => {
  switch (action.type) {
    case SHOW_ERROR:
      return { ...state, error: action.payload };
    case HIDE_ERROR:
      return { ...state, error: null };

    case SIGN_IN:
      return { ...state, user: action.payload };
    case SIGN_OUT:
      return initalValue;

    default:
      return state;
  }
};

export default GlobalReducer;
