import { Home } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { Drawer } from "../../Components/Drawer";
import useSWR, { useSWRConfig } from "swr";
import { SHOW_ERROR } from "../../Context/globalActions.types";
import globalContext from "../../Context/globalContext";
import { Instance } from "../../Config";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

export default function FarmerProfile() {
  const [formData, setFormData] = useState({
    ffId: "",
    farmName: "",
    farmSurveyNo: "",
    listFarm: "",
  });

  const { mutate } = useSWRConfig();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    Instance.post("/farm", {
      farmName: formData.farmName,
      farmSurveyNo: formData.farmSurveyNo,
      listFarm: formData.listFarm,
    })
      .then(() => {
        handleClose();
        mutate("/profile");
        setFormData({
          farmName: "",
          farmSurveyNo: "",
          listFarm: "",
        });
      })
      .catch((err) => {
        dispatch({
          type: SHOW_ERROR,
          payload: err.response.data.message || "Something Went Wrong",
        });
      });
  };

  const handleDeleteFarm = () => {
    Instance.delete(`/farm/${formData.ffId}`)
      .then(() => {
        handleClose1();
        mutate("/profile");
        setFormData({
          ffId: "",
          farmName: "",
          farmSurveyNo: "",
          listFarm: "",
        });
      })
      .catch((err) => {
        dispatch({
          type: SHOW_ERROR,
          payload: err.response.data.message || "Something Went Wrong",
        });
      });
  };

  const { dispatch } = useContext(globalContext);

  const [rows, setRows] = useState([]);

  const { data, error } = useSWR("/profile");

  useEffect(() => {
    if (data) setRows(data.data.data);
  }, [data]);

  useEffect(() => {
    if (error)
      dispatch({
        type: SHOW_ERROR,
        payload: "Something went wrong",
      });
  }, [error]);

  return (
    <Drawer>
      <AddFarmsDialog
        handleClose={handleClose}
        open={open}
        formData={formData}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        setFormData={setFormData}
      />
      <DeleteFarmsDialog
        handleClose={handleClose1}
        handleSubmit={handleDeleteFarm}
        open={open1}
      />
      <div className="mt-10 mx-5">
        <nav
          className="flex w-full items-start rounded-md bg-gray-100 p-2"
          aria-label="Breadcrumb"
        >
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <a
                href="#"
                className="ml-1 inline-flex text-sm font-medium text-gray-800 hover:underline md:ml-2"
              >
                <Home className="mr-4 h-4 w-4" />
                Admin
              </a>
            </li>
            <li>
              <div className="flex items-center">
                <span className="mx-2.5 text-gray-800 ">/</span>
                <a
                  href="#"
                  className="ml-1 text-sm font-medium text-gray-800 hover:underline md:ml-2"
                >
                  Profile
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <div>
          <h2 className="text-2xl font-bold leading-tight text-black my-4">
            Profile
          </h2>
          <div className="grid grid-cols-4 gap-x-3">
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                First Name:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].fName : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                Last Name:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].lName : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                User Name:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].username : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                Address:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].usersf.addr : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                City:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].usersf.city : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                State:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].usersf.state : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                Pincode:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].usersf.pincode : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                Mobile No:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={rows.length !== 0 ? rows[0].usersf.mobile : ""}
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                Irrigation:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={
                    rows.length !== 0 && rows[0].usersf.irrig
                      ? rows[0].usersf.irrig.join(", ")
                      : ""
                  }
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                Season:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={
                    rows.length !== 0 && rows[0].usersf.season
                      ? rows[0].usersf.season.join(", ")
                      : ""
                  }
                ></input>
              </div>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                Source:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  disabled
                  value={
                    rows.length !== 0 && rows[0].usersf.source
                      ? rows[0].usersf.source.join(", ")
                      : ""
                  }
                ></input>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              onClick={handleClickOpen}
              type="button"
              className="inline-flex items-center justify-center rounded-md bg-black px-3.5 py-1 font-semibold leading-7 text-white hover:bg-black/80"
            >
              Add Farms
            </button>
          </div>
          <TableContainer component={Paper} className="mt-4">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Farm Name</TableCell>
                  <TableCell>Farm Survey No</TableCell>
                  <TableCell>List Farm</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length != 0
                  ? rows[0].usersf.farmerfarms.map((row) => (
                      <TableRow key={row.ffId}>
                        <TableCell component="th" scope="row">
                          {row.ffId}
                        </TableCell>
                        <TableCell>{row.farmName}</TableCell>
                        <TableCell>{row.farmSurveyNo}</TableCell>
                        <TableCell>{row.listFarm ? "Yes" : "No"}</TableCell>
                        <TableCell>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                ffId: row.ffId,
                              });
                              handleClickOpen1();
                            }}
                          >
                            <DeleteOutlineIcon />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <div className="mt-3 text-center">
            <button
              onClick={handleSubmit}
              type="button"
              className="inline-flex items-center justify-center rounded-md bg-black px-3.5 py-1 font-semibold leading-7 text-white hover:bg-black/80"
            >
              Submit
            </button>
          </div> */}
        </div>
      </div>
    </Drawer>
  );
}

function AddFarmsDialog({
  open,
  handleClose,
  handleInputChange,
  handleSubmit,
  formData,
  setFormData,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Add Farm</DialogTitle>
      <DialogContent>
        <form>
          <div>
            <label htmlFor="" className="text-base font-medium text-gray-900">
              Farm Name
            </label>
            <div className="mt-2">
              <input
                className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type="text"
                placeholder="Farm Name"
                name="farmName"
                value={formData.farmName}
                onChange={handleInputChange}
              ></input>
            </div>
          </div>
          <div className="mt-2">
            <label htmlFor="" className="text-base font-medium text-gray-900">
              Farm Survey No
            </label>
            <div className="mt-2">
              <input
                className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type="text"
                placeholder="Farm Survey No"
                name="farmSurveyNo"
                value={formData.farmSurveyNo}
                onChange={handleInputChange}
              ></input>
            </div>
          </div>
          <div className="mt-3">
            <div className="flex items-center justify-between">
              <label htmlFor="" className="text-base font-medium text-gray-900">
                {" "}
                List Farm{" "}
              </label>
            </div>
            <div class="flex items-center mt-1">
              <input
                id="Yes"
                name="listFarm"
                type="radio"
                value="Well"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                checked={formData.listFarm === "Yes" ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFormData({
                      ...formData,
                      listFarm: "Yes",
                    });
                  } else {
                    setFormData({
                      ...formData,
                      listFarm: "",
                    });
                  }
                }}
              />
              <label
                for="Yes"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Yes
              </label>
            </div>

            <div className="flex items-center mt-2">
              <input
                id="Yes"
                name="listFarm"
                type="radio"
                value="No"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                checked={formData.listFarm === "No" ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFormData({
                      ...formData,
                      listFarm: "No",
                    });
                  } else {
                    setFormData({
                      ...formData,
                      listFarm: "",
                    });
                  }
                }}
              />
              <label
                for="No"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                No
              </label>
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSubmit} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeleteFarmsDialog({ open, handleClose, handleSubmit }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Delete Farm</DialogTitle>
      <DialogContent>Are you sure you want to delete the farm?</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
