import { Home } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { Drawer } from "../../Components/Drawer";
import useSWR, { useSWRConfig } from "swr";
import { SHOW_ERROR } from "../../Context/globalActions.types";
import globalContext from "../../Context/globalContext";
import { Instance } from "../../Config";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

export default function FarmerBidData() {
  const [rows, setRows] = useState([]);

  const [formValue, setFormValue] = useState({
    file: "",
    isAccepted: "",
    bId: "",
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { dispatch } = useContext(globalContext);

  const { mutate } = useSWRConfig();

  const { data, error } = useSWR("/bid");

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("bId", formValue.bId);
    formData.append("isAccepted", formValue.isAccepted);
    if (formValue.file) formData.append("doc", formValue.file);

    Instance.put("/bid/status", formData)
      .then(() => {
        mutate("/bid");
        handleClose();
      })
      .catch((err) => {
        dispatch({
          type: SHOW_ERROR,
          payload: err.response.data.message || "Something Went Wrong",
        });
      });
  };

  useEffect(() => {
    if (data) setRows(data.data.data);
  }, [data]);

  useEffect(() => {
    if (error)
      dispatch({
        type: SHOW_ERROR,
        payload: "Something went wrong",
      });
  }, [error]);

  return (
    <Drawer>
      <BidDialog
        handleClose={handleClose}
        open={open}
        handleSubmit={handleSubmit}
        setFormValue={setFormValue}
        formValue={formValue}
      />
      <div className="mt-10 mx-5">
        <nav
          className="flex w-full items-start rounded-md bg-gray-100 p-2"
          aria-label="Breadcrumb"
        >
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <a
                href="#"
                className="ml-1 inline-flex text-sm font-medium text-gray-800 hover:underline md:ml-2"
              >
                <Home className="mr-4 h-4 w-4" />
                Admin
              </a>
            </li>
            <li>
              <div className="flex items-center">
                <span className="mx-2.5 text-gray-800 ">/</span>
                <a
                  href="#"
                  className="ml-1 text-sm font-medium text-gray-800 hover:underline md:ml-2"
                >
                  Bid Data
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <div>
          <h2 className="text-2xl font-bold leading-tight text-black my-4">
            Biding Data
          </h2>
          <div className="grid grid-cols-4 gap-7">
            {rows.map((e) => (
              <div className="h-full w-full rounded-lg shadow cursor-pointer mb-3">
                <div className="flex">
                  <div>
                    <div className="font-semibold ml-2 mt-2">
                      Investor Name:
                    </div>
                    <div className="ml-2 text-sm">
                      {e.investorDtl.users.fName}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="font-semibold ml-2 mt-2">
                    Farmer Farm Name:
                  </div>
                  <div className="ml-2 text-sm">{e.farmerFarms.farmName}</div>
                </div>
                <div>
                  <div className="font-semibold ml-2 mt-2">Rate:</div>
                  <div className="ml-2 text-sm">₹{e.rate}</div>
                </div>
                {e.isAccepted == null ? (
                  <div className="flex justify-evenly mt-2">
                    <button
                      onClick={() => {
                        setFormValue({
                          ...formValue,
                          bId: e.bId,
                          isAccepted: false,
                        });
                        handleSubmit();
                      }}
                      type="button"
                      className="inline-flex items-center justify-center rounded-md bg-red-600 px-3.5 py-1 font-semibold leading-7 text-white hover:bg-red-600/80"
                    >
                      Decline
                    </button>
                    <button
                      onClick={() => {
                        setFormValue({
                          ...formValue,
                          bId: e.bId,
                          isAccepted: true,
                        });
                        handleClickOpen();
                        // handleSubmit(e.bId, true);
                      }}
                      type="button"
                      className="inline-flex items-center justify-center rounded-md bg-green-600 px-3.5 py-1 font-semibold leading-7 text-white hover:bg-green-600/80"
                    >
                      Accept
                    </button>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

const BidDialog = ({
  open,
  handleClose,
  handleSubmit,
  setFormValue,
  formValue,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Accept Bid</DialogTitle>
      <DialogContent>
        <div>Doc Upload:</div>
        <input
          type="file"
          onChange={(e) => {
            setFormValue({
              ...formValue,
              file: e.target.files[0],
            });
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
