import { Home } from "lucide-react";
import React from "react";
import { Drawer } from "../../Components/Drawer";

export default function Dashboard() {
  return (
    <Drawer>
      <div className="mt-10 mx-5">
        <nav
          className="flex w-full items-start rounded-md bg-gray-100 p-2"
          aria-label="Breadcrumb"
        >
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <a
                href="#"
                className="ml-1 inline-flex text-sm font-medium text-gray-800 hover:underline md:ml-2"
              >
                <Home className="mr-4 h-4 w-4" />
                Admin
              </a>
            </li>
            <li>
              <div className="flex items-center">
                <span className="mx-2.5 text-gray-800 ">/</span>
                <a
                  href="#"
                  className="ml-1 text-sm font-medium text-gray-800 hover:underline md:ml-2"
                >
                  Dashboard
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <h2 className="text-xl font-bold leading-tight text-black my-4">
          System Overview Data
        </h2>
        <div className="grid grid-cols-4">
          <div class="flex flex-col justify-center h-32 w-48 px-4 py-4 bg-white border border-gray-300 rounded shadow">
            <div>
              <p class="text-3xl font-semibold text-center text-gray-800">43</p>
              <p class="text-sm text-center text-gray-500 leading-5">
                Total Investor
              </p>
            </div>
          </div>
          <div class="flex flex-col justify-center h-32 w-48 px-4 py-4 bg-white border border-gray-300 rounded shadow">
            <div>
              <p class="text-3xl font-semibold text-center text-gray-800">43</p>
              <p class="text-sm text-center text-gray-500 leading-5">
                Total Farmer
              </p>
            </div>
          </div>
          <div class="flex flex-col justify-center h-32 w-48 px-4 py-4 bg-white border border-gray-300 rounded shadow">
            <div>
              <p class="text-3xl font-semibold text-center text-gray-800">43</p>
              <p class="text-sm text-center text-gray-500 leading-5">
                Investor Register in Last 30 Days
              </p>
            </div>
          </div>
          <div class="flex flex-col justify-center h-32 w-48 px-4 py-4 bg-white border border-gray-300 rounded shadow">
            <div>
              <p class="text-3xl font-semibold text-center text-gray-800">43</p>
              <p class="text-sm text-center text-gray-500 leading-5">
                Farmer Register in Last 30 Days
              </p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
