import { ArrowLeft, ArrowRight } from "lucide-react";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Instance } from "../Config";
import { SHOW_ERROR, SIGN_IN } from "../Context/globalActions.types";
import globalContext from "../Context/globalContext";

export default function Onboarding() {
  const { dispatch } = useContext(globalContext);

  const [step, setStep] = useState(0);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    role: "",
    type: "",
    mobileNo: "",
    addr: "",
    city: "",
    state: "",
    pincode: "",
    farmDtl: [],
    source: [],
    irrig: [],
    season: [],
  });

  const [errorMessage, setErrorMessage] = useState({
    type: "",
    message: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.type === "") {
      setErrorMessage({
        message: "Please Enter select Valid Type",
        type: "type",
      });
      return;
    }

    if (formData.mobileNo === "" || formData.mobileNo.length !== 10) {
      setErrorMessage({
        message: "Please Enter select Valid mobile No",
        type: "mobileNo",
      });
      return;
    }

    if (formData.addr === "") {
      setErrorMessage({
        message: "Please Enter Valid Address",
        type: "addr",
      });
      return;
    }

    if (formData.city === "") {
      setErrorMessage({
        message: "Please Enter Valid city",
        type: "city",
      });
      return;
    }

    if (formData.state === "") {
      setErrorMessage({
        message: "Please Enter Valid state",
        type: "state",
      });
      return;
    }

    if (formData.pincode === "") {
      setErrorMessage({
        message: "Please Enter Valid Pincode",
        type: "pincode",
      });
      return;
    }

    setErrorMessage({
      message: "",
      type: "",
    });

    setIsLoading(true);

    Instance.post(
      "/signup/onboarding",
      formData.role === "Investor"
        ? {
            role: 0,
            type: formData.type,
            mobileNo: formData.mobileNo,
            addr: formData.addr,
            city: formData.city,
            state: formData.state,
            pincode: formData.pincode,
          }
        : {
            role: 1,
            type: formData.type,
            mobileNo: formData.mobileNo,
            addr: formData.addr,
            city: formData.city,
            state: formData.state,
            pincode: formData.pincode,
            farmDtl: formData.farmDtl,
            source: formData.source,
            irrig: formData.irrig,
            season: formData.season,
          }
    )
      .then((res) => {
        setFormData({
          role: "",
          type: "",
          mobileNo: "",
          addr: "",
          city: "",
          state: "",
          pincode: "",
          farmDtl: [],
          source: [],
          irrig: [],
          season: [],
        });
        localStorage.setItem("token", res.data.token);
        dispatch({
          type: SIGN_IN,
          payload: res.data.token,
        });
        if (res.data.role === "0") {
          navigate("/");
        } else if (res.data.role === "1") {
          navigate("/");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch({
          type: SHOW_ERROR,
          payload: err.response.data.message || "Something Went Wrong",
        });
      });
  };

  return (
    <div className="flex items-center justify-center px-4 py-10 sm:px-6 sm:py-16 lg:px-8 lg:py-24">
      <div className="xl:mx-auto xl:w-full xl:max-w-md 2xl:max-w-lg">
        <h2 className="text-center text-3xl font-bold leading-tight text-black">
          Welcome to the <br />
          <span className="text-purple-400">Farmer Marketplace</span>
        </h2>
        <div className="mt-10">
          {step === 0 ? (
            <div>
              <div className="text-center font-semibold">
                Please Select the user type
              </div>
              <div className="grid grid-cols-2 mt-10 ml-12">
                <div
                  className={`h-40 w-40 shadow rounded-lg bg-white cursor-pointer ${
                    formData.role === "Investor" ? "border border-blue-600" : ""
                  }  `}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      role: "Investor",
                    });
                  }}
                >
                  <img
                    src="/img/investor.png"
                    alt="investor-img"
                    className="mt-3"
                  />
                  <p className="text-center font-semibold mt-2">Investor</p>
                </div>
                <div
                  className={`h-40 w-40 shadow rounded-lg bg-white cursor-pointer ${
                    formData.role === "Farmer" ? "border border-blue-600" : ""
                  } `}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      role: "Farmer",
                    });
                  }}
                >
                  <img
                    src="/img/farmer.png"
                    alt="farmer-img"
                    className="mt-3"
                  />
                  <p className="text-center font-semibold mt-2">Farmer</p>
                </div>
              </div>
              <div className="flex justify-end mt-16">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md bg-black px-3.5 py-1 font-semibold leading-7 text-white hover:bg-black/80"
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Next <ArrowRight className="ml-2" size={16} />
                </button>
              </div>
            </div>
          ) : null}
          {step === 1 ? (
            <div>
              <div className="mt-3">
                <label
                  for="countries"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Select the type
                </label>
                <select
                  id="countries"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="type"
                  onChange={handleInputChange}
                >
                  <option selected>Type</option>
                  <option value="Individual">Individual</option>
                  <option value="Group">Group</option>
                </select>
              </div>
              <div className="mt-3">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor=""
                    className="text-base font-medium text-gray-900"
                  >
                    {" "}
                    Mobile No{" "}
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                    type="tel"
                    placeholder="Mobile No"
                    name="mobileNo"
                    value={formData.mobileNo}
                    onChange={handleInputChange}
                  ></input>
                </div>
                {errorMessage.type === "mobileNo" ? (
                  <div className="text-red-500 text-xs mt-1">
                    {errorMessage.message}
                  </div>
                ) : null}
              </div>
              <div className="mt-3">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor=""
                    className="text-base font-medium text-gray-900"
                  >
                    {" "}
                    Address{" "}
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                    type="text"
                    placeholder="Address"
                    name="addr"
                    value={formData.addr}
                    onChange={handleInputChange}
                  ></input>
                </div>
                {errorMessage.type === "addr" ? (
                  <div className="text-red-500 text-xs mt-1">
                    {errorMessage.message}
                  </div>
                ) : null}
              </div>
              <div className="grid grid-cols-2 gap-x-3 mt-3">
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      City{" "}
                    </label>
                  </div>
                  <div className="mt-2">
                    <input
                      className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                      type="text"
                      placeholder="City"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    ></input>
                  </div>
                  {errorMessage.type === "city" ? (
                    <div className="text-red-500 text-xs mt-1">
                      {errorMessage.message}
                    </div>
                  ) : null}
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      State{" "}
                    </label>
                  </div>
                  <div className="mt-2">
                    <input
                      className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                      type="text"
                      placeholder="State"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                    ></input>
                  </div>
                  {errorMessage.type === "state" ? (
                    <div className="text-red-500 text-xs mt-1">
                      {errorMessage.message}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-3">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor=""
                    className="text-base font-medium text-gray-900"
                  >
                    {" "}
                    Pincode{" "}
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                    type="number"
                    placeholder="Pincode"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleInputChange}
                  ></input>
                </div>
                {errorMessage.type === "pincode" ? (
                  <div className="text-red-500 text-xs mt-1">
                    {errorMessage.message}
                  </div>
                ) : null}
              </div>
              {formData.role === "Farmer" ? (
                <>
                  <div className="flex mt-3">
                    <div className="font-semibold">Farm Detail:</div>
                    <div className="flex grow justify-end">
                      <button
                        type="button"
                        className="rounded-md border border-black px-4 py-1.5 text-sm font-semibold text-black shadow-sm"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            farmDtl: [
                              ...formData.farmDtl,
                              {
                                farmName: "",
                                farmSurveyNo: "",
                              },
                            ],
                          });
                        }}
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        className="rounded bg-red-500 text-white px-4 py-1.5 text-sm font-semibold shadow-sm ml-2"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            farmDtl: formData.farmDtl.slice(
                              0,
                              formData.farmDtl.length - 1
                            ),
                          });
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                  {formData.farmDtl.map((e, index) => (
                    <div className="grid grid-cols-5 gap-x-3 mt-2">
                      <div className="flex flex-col justify-center">
                        <div className="text-base font-medium text-gray-900">
                          # {index + 1}
                        </div>
                      </div>
                      <div className="col-span-2">
                        <div className="flex items-center justify-between">
                          <label
                            htmlFor=""
                            className="text-base font-medium text-gray-900"
                          >
                            {" "}
                            Farm Survey No{" "}
                          </label>
                        </div>
                        <div className="mt-2">
                          <input
                            className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                            type="text"
                            placeholder="Farm Survey No"
                          ></input>
                        </div>
                      </div>
                      <div className="col-span-2">
                        <div className="flex items-center justify-between">
                          <label
                            htmlFor=""
                            className="text-base font-medium text-gray-900"
                          >
                            Farm Name{" "}
                          </label>
                        </div>
                        <div className="mt-2">
                          <input
                            className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                            type="text"
                            placeholder="Farm Name"
                          ></input>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="mt-3">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor=""
                        className="text-base font-medium text-gray-900"
                      >
                        {" "}
                        Sources of drinking water{" "}
                      </label>
                    </div>
                    <div class="flex items-center mt-1">
                      <input
                        id="Well"
                        type="checkbox"
                        value="Well"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={
                          formData.source.filter((e) => e === "Well").length ===
                          0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData({
                              ...formData,
                              source: [...formData.source, e.target.value],
                            });
                          } else {
                            formData.source.splice(
                              formData.source.indexOf(e.target.value),
                              1
                            );
                            setFormData({
                              ...formData,
                              source: formData.source,
                            });
                          }
                        }}
                      />
                      <label
                        for="Well"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Well
                      </label>
                    </div>
                    <div className="flex items-center mt-2">
                      <input
                        id="Bore"
                        type="checkbox"
                        value="Bore"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={
                          formData.source.filter((e) => e === "Bore").length ===
                          0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData({
                              ...formData,
                              source: [...formData.source, e.target.value],
                            });
                          } else {
                            formData.source.splice(
                              formData.source.indexOf(e.target.value),
                              1
                            );
                            setFormData({
                              ...formData,
                              source: formData.source,
                            });
                          }
                        }}
                      />
                      <label
                        for="Bore"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Bore
                      </label>
                    </div>
                    <div className="flex items-center mt-2">
                      <input
                        id="Canal"
                        type="checkbox"
                        value="Canal"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={
                          formData.source.filter((e) => e === "Canal")
                            .length === 0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData({
                              ...formData,
                              source: [...formData.source, e.target.value],
                            });
                          } else {
                            formData.source.splice(
                              formData.source.indexOf(e.target.value),
                              1
                            );
                            setFormData({
                              ...formData,
                              source: formData.source,
                            });
                          }
                        }}
                      />
                      <label
                        for="Canal"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Canal
                      </label>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor=""
                        className="text-base font-medium text-gray-900"
                      >
                        {" "}
                        Facility of drinking water{" "}
                      </label>
                    </div>
                    <div className="flex items-center mt-1">
                      <input
                        id="Drip method"
                        type="checkbox"
                        value="Drip method"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={
                          formData.irrig.filter((e) => e === "Drip method")
                            .length === 0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData({
                              ...formData,
                              irrig: [...formData.irrig, e.target.value],
                            });
                          } else {
                            formData.irrig.splice(
                              formData.irrig.indexOf(e.target.value),
                              1
                            );
                            setFormData({
                              ...formData,
                              irrig: formData.irrig,
                            });
                          }
                        }}
                      />
                      <label
                        for="Drip method"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Drip method
                      </label>
                    </div>
                    <div className="flex items-center mt-2">
                      <input
                        id="flood"
                        type="checkbox"
                        value="flood"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={
                          formData.irrig.filter((e) => e === "flood").length ===
                          0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData({
                              ...formData,
                              irrig: [...formData.irrig, e.target.value],
                            });
                          } else {
                            formData.irrig.splice(
                              formData.irrig.indexOf(e.target.value),
                              1
                            );
                            setFormData({
                              ...formData,
                              irrig: formData.irrig,
                            });
                          }
                        }}
                      />
                      <label
                        for="flood"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Flood
                      </label>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor=""
                        className="text-base font-medium text-gray-900"
                      >
                        {" "}
                        Crop season{" "}
                      </label>
                    </div>
                    <div className="flex items-center mt-1">
                      <input
                        id="Kharif"
                        type="checkbox"
                        value="Kharif"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={
                          formData.season.filter((e) => e === "Kharif")
                            .length === 0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData({
                              ...formData,
                              season: [...formData.season, e.target.value],
                            });
                          } else {
                            formData.season.splice(
                              formData.season.indexOf(e.target.value),
                              1
                            );
                            setFormData({
                              ...formData,
                              season: formData.season,
                            });
                          }
                        }}
                      />
                      <label
                        for="Kharif"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Kharif
                      </label>
                    </div>
                    <div className="flex items-center mt-2">
                      <input
                        id="Rabi"
                        type="checkbox"
                        value="Rabi"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={
                          formData.season.filter((e) => e === "Rabi").length ===
                          0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData({
                              ...formData,
                              season: [...formData.season, e.target.value],
                            });
                          } else {
                            formData.season.splice(
                              formData.season.indexOf(e.target.value),
                              1
                            );
                            setFormData({
                              ...formData,
                              season: formData.season,
                            });
                          }
                        }}
                      />
                      <label
                        for="Rabi"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Rabi
                      </label>
                    </div>
                    <div className="flex items-center mt-2">
                      <input
                        id="Zaid"
                        type="checkbox"
                        value="Zaid"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={
                          formData.season.filter((e) => e === "Zaid").length ===
                          0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData({
                              ...formData,
                              season: [...formData.season, e.target.value],
                            });
                          } else {
                            formData.season.splice(
                              formData.season.indexOf(e.target.value),
                              1
                            );
                            setFormData({
                              ...formData,
                              season: formData.season,
                            });
                          }
                        }}
                      />
                      <label
                        for="Zaid"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Zaid
                      </label>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="flex justify-between mt-16">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md bg-black px-3.5 py-1 font-semibold leading-7 text-white hover:bg-black/80"
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  <ArrowLeft className="mr-2" size={16} /> Previous
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md bg-black px-3.5 py-1 font-semibold leading-7 text-white hover:bg-black/80"
                  onClick={handleSubmit}
                >
                  Submit <ArrowRight className="ml-2" size={16} />
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
