import { Home } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { Drawer } from "../../Components/Drawer";
import useSWR from "swr";
import { SHOW_ERROR } from "../../Context/globalActions.types";
import globalContext from "../../Context/globalContext";
import { Instance } from "../../Config";

export default function CropRegistration() {
  const [rows, setRows] = useState([]);

  const { data, error } = useSWR("/getFarmerFarms");

  const { dispatch } = useContext(globalContext);

  const [formData, setFormData] = useState({
    ffId: "",
    desc: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    Instance.post("/addCrop", {
      ffId: formData.ffId,
      desc: formData.desc,
    })
      .then(() => {
        setFormData({
          desc: "",
          ffId: "",
        });
      })
      .catch((err) => {
        dispatch({
          type: SHOW_ERROR,
          payload: err.response.data.message || "Something Went Wrong",
        });
      });
  };

  useEffect(() => {
    console.log(data);
    if (data) setRows(data.data.data);
  }, [data]);

  useEffect(() => {
    if (error)
      dispatch({
        type: SHOW_ERROR,
        payload: "Something went wrong",
      });
  }, [error]);

  return (
    <Drawer>
      <div className="mt-10 mx-5">
        <nav
          className="flex w-full items-start rounded-md bg-gray-100 p-2"
          aria-label="Breadcrumb"
        >
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <a
                href="#"
                className="ml-1 inline-flex text-sm font-medium text-gray-800 hover:underline md:ml-2"
              >
                <Home className="mr-4 h-4 w-4" />
                Admin
              </a>
            </li>
            <li>
              <div className="flex items-center">
                <span className="mx-2.5 text-gray-800 ">/</span>
                <a
                  href="#"
                  className="ml-1 text-sm font-medium text-gray-800 hover:underline md:ml-2"
                >
                  Crop Registration
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <div>
          <h2 className="text-2xl font-bold leading-tight text-black my-4">
            Crop Registration
          </h2>
          <div className="grid grid-cols-4 gap-x-3">
            <div>
              <label
                for="countries"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Select the Farmer Farm
              </label>
              <select
                id="countries"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                name="ffId"
                onChange={handleInputChange}
              >
                <option selected>Farmer Farm</option>
                {rows.length != 0
                  ? rows[0].farmerfarms.map((e) => (
                      <option value={e.ffId}>{e.farmName}</option>
                    ))
                  : null}
              </select>
            </div>
            <div>
              <label htmlFor="" className="text-base font-medium text-gray-900">
                Description:
              </label>
              <div className="mt-2">
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="text"
                  placeholder="Description"
                  name="desc"
                  onChange={handleInputChange}
                  value={formData.desc}
                ></input>
              </div>
            </div>
          </div>
          <div className="mt-3 text-center">
            <button
              onClick={handleSubmit}
              type="button"
              className="inline-flex items-center justify-center rounded-md bg-black px-3.5 py-1 font-semibold leading-7 text-white hover:bg-black/80"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
