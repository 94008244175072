import React, { useContext, useEffect, useState } from "react";
import { BarChart, Newspaper, BellRing, LogOut, User } from "lucide-react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import GlobalContext from "../Context/globalContext";
import { SIGN_OUT } from "../Context/globalActions.types";
import jwtDecode from "jwt-decode";

export function Drawer(props) {
  const location = useLocation();

  const [role, setRole] = useState(0);

  const navigate = useNavigate();

  const { dispatch } = useContext(GlobalContext);

  useEffect(() => {
    const decode = jwtDecode(localStorage.getItem("token"));
    setRole(decode.role);
  }, []);

  return (
    <div className="flex">
      <aside className="flex h-screen w-64 flex-col overflow-y-auto border-r bg-black px-5 py-8 sticky top-0">
        <div className="text-white">Farmer MarketPlace</div>
        <div className="mt-6 flex flex-1 flex-col justify-between">
          <nav className="-mx-3 space-y-6">
            {role == 2 ? (
              <div className="space-y-3 ">
                <label className="px-3 text-xs font-semibold uppercase text-white">
                  analytics
                </label>
                <Link
                  to="/admin/dashboard"
                  className={
                    location.pathname === "/admin/dashboard"
                      ? "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 bg-gray-50 text-gray-700"
                      : "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
                  }
                >
                  <BarChart className="h-5 w-5" aria-hidden="true" />
                  <span className="mx-2 text-sm font-medium">Dashboard</span>
                </Link>
              </div>
            ) : null}
            <div className="space-y-3 ">
              <label className="px-3 text-xs font-semibold uppercase text-white">
                System Management
              </label>
              {role == 2 ? (
                <>
                  <Link
                    to="/admin/farmer"
                    className={
                      location.pathname === "/admin/farmer"
                        ? "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 bg-gray-50 text-gray-700"
                        : "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
                    }
                  >
                    <Newspaper className="h-5 w-5" aria-hidden="true" />
                    <span className="mx-2 text-sm font-medium">Farmer</span>
                  </Link>
                  <Link
                    to="/admin/investor"
                    className={
                      location.pathname === "/admin/investor"
                        ? "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 bg-gray-50 text-gray-700"
                        : "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
                    }
                  >
                    <BellRing className="h-5 w-5" aria-hidden="true" />
                    <span className="mx-2 text-sm font-medium">Investor</span>
                  </Link>
                </>
              ) : null}
              {role == 1 ? (
                <>
                  <Link
                    to="/farmer/cropdtl"
                    className={
                      location.pathname === "/farmer/cropdtl"
                        ? "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 bg-gray-50 text-gray-700"
                        : "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
                    }
                  >
                    <BellRing className="h-5 w-5" aria-hidden="true" />
                    <span className="mx-2 text-sm font-medium">
                      Crop Details
                    </span>
                  </Link>
                  <Link
                    to="/farmer/cropreg"
                    className={
                      location.pathname === "/farmer/cropreg"
                        ? "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 bg-gray-50 text-gray-700"
                        : "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
                    }
                  >
                    <BellRing className="h-5 w-5" aria-hidden="true" />
                    <span className="mx-2 text-sm font-medium">
                      Crop Registration
                    </span>
                  </Link>
                  <Link
                    to="/farmer/profile"
                    className={
                      location.pathname === "/farmer/profile"
                        ? "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 bg-gray-50 text-gray-700"
                        : "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
                    }
                  >
                    <User className="h-5 w-5" aria-hidden="true" />
                    <span className="mx-2 text-sm font-medium">Profile</span>
                  </Link>
                  <Link
                    to="/farmer/biddata"
                    className={
                      location.pathname === "/farmer/biddata"
                        ? "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 bg-gray-50 text-gray-700"
                        : "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
                    }
                  >
                    <BellRing className="h-5 w-5" aria-hidden="true" />
                    <span className="mx-2 text-sm font-medium">
                      Biding Data
                    </span>
                  </Link>
                </>
              ) : null}

              {role == 0 ? (
                <>
                  <Link
                    to="/investor/farmlisting"
                    className={
                      location.pathname === "/investor/farmlisting"
                        ? "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 bg-gray-50 text-gray-700"
                        : "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
                    }
                  >
                    <BellRing className="h-5 w-5" aria-hidden="true" />
                    <span className="mx-2 text-sm font-medium">
                      Farm Listing
                    </span>
                  </Link>
                  <Link
                    to="/investor/biddata"
                    className={
                      location.pathname === "/investor/biddata"
                        ? "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 bg-gray-50 text-gray-700"
                        : "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
                    }
                  >
                    <BellRing className="h-5 w-5" aria-hidden="true" />
                    <span className="mx-2 text-sm font-medium">
                      Biding Data
                    </span>
                  </Link>
                  <Link
                    to="/investor/profile"
                    className={
                      location.pathname === "/investor/profile"
                        ? "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 bg-gray-50 text-gray-700"
                        : "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
                    }
                  >
                    <User className="h-5 w-5" aria-hidden="true" />
                    <span className="mx-2 text-sm font-medium">Profile</span>
                  </Link>
                </>
              ) : null}
              {role == 2 ? (
                <>
                  <Link
                    to="/admin/marketplace"
                    className={
                      location.pathname === "/admin/marketplace"
                        ? "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 bg-gray-50 text-gray-700"
                        : "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
                    }
                  >
                    <BellRing className="h-5 w-5" aria-hidden="true" />
                    <span className="mx-2 text-sm font-medium">
                      MarketPlace
                    </span>
                  </Link>
                  <Link
                    to="/admin/biddata"
                    className={
                      location.pathname === "/admin/biddata"
                        ? "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 bg-gray-50 text-gray-700"
                        : "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
                    }
                  >
                    <BellRing className="h-5 w-5" aria-hidden="true" />
                    <span className="mx-2 text-sm font-medium">
                      Biding Data
                    </span>
                  </Link>
                  <Link
                    to="/admin/users"
                    className={
                      location.pathname === "/admin/users"
                        ? "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 bg-gray-50 text-gray-700"
                        : "flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
                    }
                  >
                    <BellRing className="h-5 w-5" aria-hidden="true" />
                    <span className="mx-2 text-sm font-medium">Users</span>
                  </Link>
                </>
              ) : null}
              <div
                onClick={() => {
                  localStorage.removeItem("token");
                  dispatch({
                    type: SIGN_OUT,
                  });
                  navigate("/");
                }}
                className="cursor-pointer flex transform items-center rounded-lg px-3 py-2 text-gray-200 transition-colors duration-300 hover:bg-gray-50 hover:text-gray-700"
              >
                <LogOut className="h-5 w-5" aria-hidden="true" />
                <span className="mx-2 text-sm font-medium">LogOut</span>
              </div>
            </div>
          </nav>
        </div>
      </aside>
      <main className="w-full">{props.children}</main>
    </div>
  );
}
